import { apiService } from '@/core/services';

export const USER_EARN = {
  getUserEarn,
  updateCheckUserEarn
};

const URL = {
  API: 'api',
  V3: 'v3',
  USER_VOUCHERS: 'user-earns',
  VOUCHER_CODE_DETAIL: 'detail',
  VERIFY: 'verify'
};

function getUserEarn(data) {
  let requestOptions = {
    params: {
      user_id: data.userId,
      voucher_code: data.voucherCode,
      voucher_id: data.voucherId,
      branch_name: data.branchName,
      branch_id: data.branchId,
      merchant_id: data.merchantId,
      total_price: data.totalPrice,
      code: data.code
    }
  };
  return apiService().get(
    `/${URL.API}/${URL.V3}/${URL.USER_VOUCHERS}/${URL.VOUCHER_CODE_DETAIL}`,
    requestOptions
  );
}

function updateCheckUserEarn(data) {
  let requestBody = {
    voucher_code: data.voucherCode,
    pin_verification: data.pinVerification,
    branch_name: data.branchName,
    branch_id: data.branchId,
    merchant_id: data.merchantId,
    user_id: data.userId,
    total_price: data.totalPrice,
    total_discount: data.totalDiscount,
    code: data.code
  };
  return apiService().post(
    `/${URL.API}/${URL.V3}/${URL.USER_VOUCHERS}/${URL.VERIFY}`,
    requestBody
  );
}
